<template>
  <div class="home">
    <div class="container--fluid main d-flex align-center">
      <div class="main-bg"></div>
      <div class="container">
        <v-row
          class="justify-center flex-column flex-md-row align-center"
        >
          <div>
            <div>
              <h1>Black Lynx</h1>
            </div>
            <div class="h1skyfest">
              <h1>FEST 2025</h1>
            </div>
            <div class="mt-4 mb-6">
              <h2>
                Фестиваль скайраннинга <br class="d-md-none">в Восточных Саянах
              </h2>
              <h2 class="mt-2 mt-md-0">
                18 &ndash; 21 июля
              </h2>
            </div>
            <div
              class="justify-center text-center mt-5 mb-10 mb-md-0 mt-md-10"
            >
              <v-btn
                :color="COLOR_ACCENT_PRIMARY"
                elevation="4"
                raised
                tile
                class="btn-reg"
                :href="`https://toplist.run/race/${TOPLIST_RACE_ID}`"
                target="_blank"
              >
                Регистрация
              </v-btn>
              <div class="text-caption white--text mt-1">на сайте toplist.run</div>
            </div>
          </div>
        </v-row>
      </div>

      <div
        class="btn-down text-center c-pointer"
        @click="$vuetify.goTo('#scroll')"
      >
        <v-icon
          class="mdi-chevron-down mdi btn-down-arrow"
          color="white"
        ></v-icon>
      </div>
    </div>

    <div
      id="scroll"
      class="container--fluid days d-flex align-center pb-15 pt-10"
    >
      <div class="container">
        <div class="text-center mb-3">
          <h3>
            Дистанции фестиваля
          </h3>
        </div>

        <v-row class="justify-md-center flex-column flex-md-row">
          <v-col class="col col-md-4 mb-10">
            <div class="h5text mb-2">
              Пик Любви <br/>2100м
            </div>

            <div class="d-flex mb-3">
              <div class="">
                <div class="name-race">Black Lynx</div>
                <div class="name-race-2 name-race">VK</div>
              </div>
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-vector-line</v-icon>
              5 км / 1250 D+
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-timer-outline</v-icon>
              4 часа
            </div>

            <div class="race mb-6 d-none d-md-flex">
              <v-icon
                class="mr-2"
                size="32"
                color="transparent"
              >mdi-trophy</v-icon>
            </div>

            <div>
              Забег на вершину пика Любви с потрясающим видом на знаменитую Тункинскую долину. Короткая по расстоянию трасса с большим набором высоты. <br><br>Для подготовленных участников.
            </div>
          </v-col>

          <v-col class="col col-md-4 mb-10">
            <div class="h5text mb-2">
              Пик Трехглавая, <br/> Южная башня 2514м
            </div>

            <div class="d-flex mb-3">
              <div class="">
                <div class="name-race">Black Lynx</div>
                <div class="name-race-2 name-race">SKYRACE</div>
              </div>
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-vector-line</v-icon>
              23 км / 1700 D+
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-timer-outline</v-icon>
              7 часов
            </div>

            <div class="race d-flex mb-6">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-trophy</v-icon>
              Чемпионат России (гонка)
            </div>

            <div class="mb-6">
              <a href="https://nakarte.me/#m=13/51.94479/102.40021&amp;l=Otm&amp;nktl=he_6rjLDOV4PY4ShpxeO2g" target="_blank">Трек дистанции</a>
            </div>

            <div>
              <b>Чемпионат России по альпинизму в дисциплине «скайраннинг-гонка».</b><br><br>
              Настоящая скайраннинговая дистанция. Суровая, беспощадная, технически сложная и невероятно красивая. Она останется в памяти каждого участника. <br><br> Только для подготовленных участников.
            </div>
          </v-col>

          <v-col class="col col-md-4 mb-10">
            <div class="h5text mb-2">
              Забег по ущельям <br/>горной реки
            </div>

            <div class="d-flex mb-3">
              <div class="">
                <div class="name-race">Black Lynx</div>
                <div class="name-race-2 name-race">Race</div>
              </div>
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-vector-line</v-icon>
              14 км / 330 D+
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-timer-outline</v-icon>
              4 часа
            </div>

            <div class="race mb-6 d-none d-md-flex">
              <v-icon
                class="mr-2"
                size="32"
                color="transparent"
              >mdi-trophy</v-icon>
            </div>

            <div class="mb-6">
              <a href="https://nakarte.me/#m=13/51.93756/102.44169&l=Otm&nktl=gCai_B03ab73Q1aOcGT7JQ" target="_blank">Трек дистанции</a>
            </div>

            <div>
              Забег до 1 кп забега Skyrace. На дистанции присутствуют технически-сложные элементы - корни, камни, нестабильная поверхность. <br><br>Эта дистанция подходит для тех, кто присматривается к скайраннингу, кто хочет попробовать Skyrace, но пока не готов бежать ее в полной версии.
            </div>
          </v-col>

          <v-col class="col col-md-4 mb-10">
            <div class="h5text mb-2">
              Вулкан <br/>Уляборский
            </div>

            <div class="d-flex mb-3">
              <div class="">
                <div class="name-race">Race</div>
                <div class="name-race-2 name-race">Volcano</div>
              </div>
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-vector-line</v-icon>
              9 км / 150 D+
            </div>

            <div class="race d-flex mb-6">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-timer-outline</v-icon>
              2 часа
            </div>

            <div class="mb-6">
              <a href="https://nakarte.me/#m=14/51.86239/102.50373&amp;l=Otm&amp;nktl=lYLJz969MVZaFjMAKD70NA" target="_blank">Трек дистанции</a>
            </div>

            <div>
              Дистанция с потрясающим видом на цветущие поля и Восточные Саяны, с забеганием на самый настоящий вулкан. <br><br>Забег хорошо подходит для любителей и тех, кто выбирает скорость.
            </div>
          </v-col>

          <v-col class="col col-md-4 mb-10">
            <div class="h5text mb-2">
              Детские забеги <br/>4-8, 9-14 лет
            </div>

            <div class="d-flex mb-3">
              <div class="">
                <div class="name-race">Black Lynx</div>
                <div class="name-race-2 name-race">Kids</div>
              </div>
            </div>

            <div class="race d-flex mb-1">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-vector-line</v-icon>
              500 м, 1000 м
            </div>

            <div class="race d-flex mb-6">
              <v-icon
                class="mr-2"
                size="32"
              >mdi-timer-outline</v-icon>
              отсутствует
            </div>

            <div>
              Забег для юных чемпионов по центральному парку курорта Аршан. <br><br>Каждому ребенку на финише будет вручен сладкий приз и настоящая медаль финишера.
            </div>
          </v-col>
        </v-row>
        <div
          class="justify-center text-center mt-6 mt-md-12"
        >
          <v-btn
            :color="COLOR_ACCENT_PRIMARY"
            elevation="4"
            raised
            tile
            class="btn-reg white--text"
            :href="`https://toplist.run/race/${TOPLIST_RACE_ID}`"
            target="_blank"
          >
            Регистрация
          </v-btn>
          <div class="text-caption mt-1">на сайте toplist.run</div>
        </div>
      </div>
    </div>
    <div class="container--fluid program d-flex align-center pb-15 pt-10">
      <div id="program" class="anchor"></div>

      <div class="container">
        <div class="text-center mb-6">
          <h3>Программа соревнований</h3>
        </div>

        <v-row class="pl-4 justify-center">
          <v-col class="px-md-6 col-md-4 col-12 mb-6">
            <div>
              День 1
            </div>
            <div class="text-red mt-4 mb-8">
              18 июля (пятница)
            </div>
            <div>
              <div class="pb-3">
                Заезд участников. Мандатная комиссия
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">17:00 - 20:00</span> – выдача стартовых пакетов на все дистанции
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">20:00</span> – брифинг по дистанции «Black Lynx VK»
              </div>
            </div>
          </v-col>

          <v-col class="px-md-6 col-md-4 col-12 mb-6">
            <div>
              День 2
            </div>

            <div class="text-red mt-4 mb-8">
              19 июля (суббота)
            </div>

            <div>
              <div class="pb-3">
                <span class="font-weight-bold">07:30 - 08:30</span> – мандатная комиссия
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">08:30</span> – открытие соревнований
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">09:00</span> – старт скоростного восхождения на пик Любви «Black Lynx VK»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">17:00 - 18:00</span> – мандатная комиссия «Black Lynx SKYRACE», «Black Lynx Race», «Volcano Race», «Black Lynx Kids»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">18:00</span> – награждение победителей и призеров «Black Lynx VK»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">18:30</span> – брифинг по дистанциям «Black Lynx SKYRACE», «Black Lynx Race», «Volcano Race», «Black Lynx Kids»
              </div>
            </div>
          </v-col>

          <v-col class="px-md-6 col-md-4 col-12 mb-6">
            <div>
              День 3
            </div>

            <div class="text-red mt-4 mb-8">
              20 июля (воскресенье)
            </div>

            <div>
              <div class="pb-3">
                <span class="font-weight-bold">07:00 - 07:30</span> – мандатная комиссия
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">07:40</span> – открытие соревнований
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">08:00</span> – старт скоростного восхождения «Black Lynx SKYRACE»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">08:30</span> – старт забега «Black Lynx Race»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">09:30</span> – старт «Volcano Race», вулкан Уляборский
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">10:00</span> – старт детского забега «Black Lynx Kids»
              </div>
              <div class="pb-3">
                <span class="font-weight-bold">15:00</span> – награждение победителей и призеров «Black Lynx SKYRACE», «Black Lynx Race», «Volcano Race», «Black Lynx Kids»
              </div>
              <div class="pb-3">
                Закрытие фестиваля
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="container--fluid information d-flex align-center pb-15 pt-10">
      <div id="info" class="anchor"></div>
      <div class="container">
        <v-row class="justify-center mb-6">
          <h3>Информация</h3>
        </v-row>

        <v-row>
          <div class="mb-8 mx-auto text-center headline">Место проведения: республика Бурятия, курорт Аршан</div>
        </v-row>

        <v-row class="justify-center mb-0 mb-md-10">
          <v-col class="px-md-5 col-md-4 col-12">
            <div class="inf-bus mb-8"></div>
            <div class="inf-question font-weight-bold mb-2">Как добраться?</div>
            <div class="inf-answer">
              <div class="mb-2">
                1. На автомобиле
              </div>
              <div class="mb-2">2. На рейсовом автобусе</div>
              <ul class="mb-2">
                <li>Иркутск − Аршан</li>
                <li>Иркутск − Нилова Пустынь</li>
                <li>Улан-Удэ − Аршан</li>
              </ul>
              <div class="mb-2">Расстояние до ближайших городов:</div>
              <ul class="mb-2">
                <li>Иркутск ~ 200 км</li>
                <li>Слюдянка ~ 120 км</li>
                <li>Улан-Удэ ~ 460 км</li>
              </ul>
            </div>
          </v-col>

          <v-col class="px-md-5 col-md-4 col-12">
            <div class="inf-home mb-8"></div>
            <div class="inf-question font-weight-bold mb-2">Где жить?</div>
            <div class="inf-answer">
              <div class="mb-2">
                <div class="mb-2">В пос. Аршан расположено множество частных гостиниц.</div>
                <div class="mb-2"> Информацию о проживании можно найти на 2GIS, 101ОТЕЛЬ, Ostrovok, группах Вконтакте (например, vk.com/tunkatravel) и прочих свободных источниках.</div>
                <div class="mb-2">Бронировать жилье желательно заранее.</div>
                <div class="mb-2">Организаторы не несут ответственности по вопросам бронирования и оплаты проживания.</div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-md-5 col-md-4 col-12">
            <div class="inf-backpack mb-8"></div>

            <div class="inf-question font-weight-bold mb-2">
              Обязательное снаряжение на дистанциях «SKYRACE» и «Race»
            </div>

            <div class="inf-answer">
              <div class="mb-2">
                − беговая обувь с агрессивным протектором;
              </div>

              <div class="mb-2">
                − стаканчик для напитков на пунктах питания;
              </div>

              <div class="mb-2">
                − бутылочка/гидратор с запасом воды 1 литр (0.5 литра на дистанцию «Black Lynx Race»);
              </div>

              <div class="mb-2">
                − загруженный GPS-трек дистанции на часах или телефоне;
              </div>

              <div class="mb-2">
                − спасательное одеяло;
              </div>

              <div class="mb-2">
                − ветрозащитная куртка с капюшоном;
              </div>

              <div class="mb-2">
                − головной убор;
              </div>

              <div class="mb-2">
                − заряженный телефон с номером организаторов, +7 (902) 767 99 62;
              </div>

              <div class="mb-2">
                − аптечка: бинт, пластырь, обезболивающее, обеззараживающее средство.
              </div>

              <div class="mt-4 mb-2 font-weight-bold">
                Внимание! Наличие данного снаряжения будет проверяться перед стартом
              </div>
            </div>
          </v-col>

          <v-col class="px-md-5 col-md-4 col-12">
            <div class="inf-backpack mb-8"></div>

            <div class="inf-question font-weight-bold mb-2">
              Обязательное снаряжение на дистанции «VK»
            </div>

            <div class="inf-answer">
              <div class="mb-2">
                − беговая обувь;
              </div>

              <div class="mb-2">
                − головной убор;
              </div>

              <div class="mb-2">
                − ветрозащитная куртка.
              </div>
              Обязательное снаряжение на дистанции «Volcano Race», Black Lynx «Kids»»: отсутствует.
              <div class="mt-4 mb-2 font-weight-bold">
                Внимание! Наличие данного снаряжения будет проверяться перед стартом
              </div>
            </div>
          </v-col>

          <v-col class="px-md-5 col-md-4 col-12">
            <div class="inf-backpack mb-8"></div>

            <div class="inf-question font-weight-bold mb-2">
              Обязательное снаряжение на дистанции «Volcano Race», Black Lynx «Kids» отсутствует.
            </div>
          </v-col>
        </v-row>

        <v-row
          class="justify-center"
        >
          <v-btn
            class="btn-reg mt-10"
            :color="COLOR_ACCENT_PRIMARY"
            elevation="4"
            raised
            tile
            :href="REGULATION_DOCUMENT_LINK"
            target="_blank"
          >
            Открыть положение
          </v-btn>
        </v-row>
      </div>
    </div>
    <div class="container--fluid gallery d-flex align-center pb-15">
      <div class="container">
        <v-row class="justify-center">
          <v-col
            v-for="(n, k) in 34"
            :key="n"
            class="d-flex child-flex col-6 col-md-3"
          >
            <v-img
              :src="require(`@/assets/gallery/photo_md_${n}.jpeg`)"
              :lazy-src="require(`@/assets/gallery/photo_sm_${n}.jpeg`)"
              aspect-ratio="1"
              class="grey lighten-2 c-pointer"
              @click="showDialog(k)"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-dialog
            v-model="dialog"
          >
            <div
              v-for="(n, k) in 34"
              :key="n"
              class="d-flex"
              :class="{ 'd-none': imageNumber !== k }"
              @click="dialog = false"
            >
              <v-img
                v-if="imageNumber === k"
                :src="require(`@/assets/gallery/photo_${n}.jpg`)"
                :lazy-src="require(`@/assets/gallery/photo_md_${n}.jpeg`)"
                max-height="90vh"
                contain
              >
              </v-img>
            </div>
          </v-dialog>
        </v-row>
      </div>
    </div>
    <div class="container--fluid registered d-flex align-center pb-15 pt-10">
      <div id="list" class="anchor"></div>
      <div class="container">
        <div class="text-center mb-3">
          <h3>
            Список участников
          </h3>
        </div>

        <div class="white--text text-center font-weight-bold text-body-2 mb-10">Полную версию списка смотрите на сайте <a
          class="white--text"
          :href="`https://toplist.run/race/${TOPLIST_RACE_ID}/registered`"
          target="_blank"
        >TOPLIST/RUN</a>
        </div>

        <div>
          <v-data-table
            :mobile-breakpoint="0"
            dark
            :headers="registeredHeaders"
            :items="registeredData"
            :items-per-page="registeredItems"
          >
            <template #item="{ item }">
              <tr>
                <td>{{ item.pos }}</td>
                <td style="min-width: 200px;">{{ `${item.user.surname} ${item.user.name}` }}</td>
                <td>{{ item.gender.shortName }}</td>
                <td style="min-width: 200px;">{{ item.club ? item.club.name : '' }}</td>
                <td style="min-width: 200px;">{{ item.city ? `${item.city.name}, ` : '' }}{{ item.region ? item.region.name : '' }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div class="container--fluid contacts d-flex align-center pb-15 pt-10">
      <div id="contacts" class="anchor"></div>
      <div class="container">
        <div class="text-center mb-5"><h3>Организаторы</h3></div>
        <v-row class="justify-center flex-column flex-md-row">
          <v-col class="col-12 col-md-4 justify-center">
            <div class="logo-org mx-auto">
              <v-img
                :src="require(`@/assets/logo-school-black.svg`)"
              />
            </div>
            <div
              class="text-center"
            >
              <v-btn
                class="btn-reg btn-school mt-4"
                :color="COLOR_ACCENT_PRIMARY"
                elevation="4"
                href="https://blacklynxteam.ru/school"
                raised
                tile
              >
                Страница школы
              </v-btn>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4 contact-items justify-center justify-md-start text-center text-md-left">
            <div class="d-flex flex-column align-start mt-4">
              <div class="name ml-4 ml-md-0">Чернов Виталий</div>
              <a href="tel:+79027679962" class="phone mb-2 ml-4 ml-md-0" >+7 (902) 767 99 62</a>
              <a href="https://t.me/joinchat/DbBOAd7Af3syODc6" target="_blank" class="telegram mb-2  ml-md-0 ml-4">Чат Telegram</a>
              <a href="https://vk.com/blacklynxfest" target="_blank" class="vk mb-2 ml-md-0 ml-4">Группа ВКонтакте</a>
              <a href="mailto:blacklynxschool@gmail.com" class="mail ml-md-0 ml-4">blacklynxschool@gmail.com</a>
            </div>
          </v-col>
        </v-row>

      </div>
    </div>
    <div class="container--fluid footer d-flex align-center">
      <div class="container">
        <v-row class="justify-center">
          <div>© {{ getYear }} Black Lynx School</div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  const TOPLIST_RACE_ID = '10135';
  const REGULATION_DOCUMENT_LINK = 'https://docs.google.com/document/d/1qkTidpRltvhE4E0e6VBN8LRIIxTWjBI3wp2gyQM7fiY/edit?usp=sharing';
  const COLOR_ACCENT_PRIMARY = '#B548E3';

  export default {
    name: 'Home',

    data: () => {
      return {
        COLOR_ACCENT_PRIMARY,
        TOPLIST_RACE_ID,
        REGULATION_DOCUMENT_LINK,

        dialog: false,
        imageNumber: 0,
        registeredData: [],
        registeredItems: 10,
        registeredHeaders: [
          {
            text: '',
            align: 'start',
            sortable: true,
            value: 'pos',
          },
          {
            text: 'Имя',
            align: 'start',
            sortable: true,
            value: 'user.surname'
          },
          {
            text: 'Пол',
            align: 'start',
            sortable: true,
            value: 'gender.name',
          },
          {
            text: 'Клуб',
            align: 'start',
            sortable: true,
          },
          {
            text: 'Нас. пункт',
            align: 'start',
            sortable: true,
          },
        ]
      }
    },

    computed: {
      getYear() {
        return new Date().getFullYear();
      },
    },

    mounted () {
      this.fetchParticipants();
    },

    methods:{
      showDialog(k) {
        this.imageNumber = k;
        this.dialog = true;
      },

      fetchParticipants () {
        axios
          .get(`/registration/registered/${TOPLIST_RACE_ID}`, { headers: {} })
          .then((response) => {
            this.registeredData = [];
            let pos = 1;
            let array = response.data;
            array.map((item) => {
              if (!this.registeredData.some((item2) => item.user.id === item2.user.id)) {
                this.$set(item, 'pos', pos);
                pos += 1;
                this.registeredData.push(item);
              }
              //this.$set(item, 'pos', key + 1);
            });
          });
      }
    }
  }
</script>
